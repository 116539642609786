<template>
    <div>
        <jy-dialog type="formDouble" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogDoubleWidth" @close="close">
            <el-tabs v-model="active" @click="change">
                <el-tab-pane name="1" label="取消派发"></el-tab-pane>
                <el-tab-pane name="2" label="终止路单"></el-tab-pane>
            </el-tabs>
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号"></jy-table-column>
                <jy-table-column label="营运日期" prop="oDate"></jy-table-column>
                <jy-table-column label="起始站" prop="sSiteNa"></jy-table-column>
                <jy-table-column label="线路" prop="routeNa"></jy-table-column>
                <jy-table-column label="车牌号" prop="vehicleNo"></jy-table-column>
                <jy-table-column label="开始时间" prop="sTime"></jy-table-column>
                <jy-table-column label="状态">
                    <template v-slot="scope">{{ scope.row.eState | formatEState }}</template>
                </jy-table-column>
                <jy-operate v-bind:list="operateList" width="80"></jy-operate>
            </jy-table>
        </jy-dialog>
    </div>
</template>

<script>
    export default {
        filters: {
            formatEState(val) {
                if (val == "2") {
                    return "已完成";
                } else if (val == "1") {
                    return "已发车";
                } else {
                    return "未发车";
                }
            }
        },
        methods: {
            init(row) {
                this.plateNum = row.plateNum;
                this.getList();
                this.dialogVisible = true;
            },
            getList() {
                this.$http.post("/waybillActual/queryVehicleActual", { plateNum: this.plateNum }).then(res => {
                    if (res.detail) {
                        this.actualVList = res.detail;
                        this.dataList = this.actualVList;
                    }
                });
                this.$http.post("/waybillPlan/queryVehicleShift", { plateNum: this.plateNum }).then(res => {
                    if (res.detail) {
                        this.unDepList = res.detail;
                    }
                });
            },
            change() {
                if (this.active === "1") {
                    this.dataList = this.actualVList;
                } else {
                    this.dataList = this.unDepList;
                }
            },
            clear() {
                let url = "/waybillPlan/cancelVehicleUnDepShift";
                let option = {
                    plateNum: this.plateNum
                };
                this.$http.post(url, option).then(data => {
                    this.$message({
                        message: data.msg,
                        type: "success"
                    });
                    this.$emit("update");
                    this.dialogVisible = false;
                });
            },
            cancel() {
                let url = "/waybillActual/cancelVehicleActual";
                let option = {
                    plateNum: this.plateNum
                };
                this.$http.post(url, option).then(data => {
                    this.$message({
                        message: data.msg,
                        type: "success"
                    });
                    this.$emit("update");
                    this.dialogVisible = false;
                });
            },
            close() {
                this.active = "1";
                this.plateNum = "";
            }
        },
        computed: {
            operateList() {
                if (this.active === "1") {
                    return [
                        {
                            name: "取消派发",
                            icon: "el-icon-edit-outline",
                            fun: this.clear,
                            isShow: () => {
                                return true;
                            }
                        }
                    ];
                } else {
                    return [
                        {
                            name: "终止路单",
                            icon: "el-icon-edit-outline",
                            fun: this.cancel,
                            isShow: () => {
                                return true;
                            }
                        }
                    ];
                }
            }
        },
        data() {
            return {
                title: "取消排班",
                plateNum: "",

                dialogVisible: false,
                active: "1",
                dataList: [],
                actualVList: [],
                unDepList: []
            };
        }
    };
</script>
<style></style>
