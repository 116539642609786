<template>
    <div class="add_aupdate_cars">
        <jy-dialog type="formDouble" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogDoubleWidth">
            <div>
                <el-form ref="addUpdateFrom" :rules="formRules" :model="formData" label-width="130px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="车牌号:" prop="plateNum">
                                <el-input v-model="formData.plateNum"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="自编号:" prop="customNo">
                                <el-input v-model="formData.customNo"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="vin:" prop="vin">
                                <el-input v-model="formData.vin"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属机构:" prop="orgId">
                                <el-input v-model="orgNa" @focus="selectInstitutionsLine"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="车辆厂商:" prop="vendor">
                                <el-input v-model="formData.vendor"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="车辆型号:" prop="model">
                                <el-input v-model="formData.model"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="车辆状态:" prop="status">
                                <el-select v-model="formData.status" placeholder="请选择">
									<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="车辆类型:" prop="vType">
                                <el-select v-model="formData.vType" placeholder="请选择">
									<el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="车辆电池容量:" prop="batterV">
                                <el-input v-model="formData.batterV"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="颜色:" prop="color">
                                <el-input v-model="formData.color"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="燃料类型:" prop="fuelT">
                                <el-select v-model="formData.fuelT" placeholder="请选择">
                                    <el-option v-for="item in fuelTs" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
							<el-form-item label="购置日期:" prop="purchaseD">
								<el-date-picker  value-format="yyyy-MM-dd" v-model="formData.purchaseD" type="date" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="座位数:" prop="seats">
                                <el-input v-model="formData.seats"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
							<el-form-item label="最大核载数:" prop="maxiNum">
								<el-input v-model="formData.maxiNum"></el-input>
							</el-form-item>
						</el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel" v-if="!disabled">取 消</el-button>
                <el-button type="primary" @click="save" v-if="!disabled">确 定</el-button>
            </div>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择机构"></institutions-tree>
    </div>
</template>
<script type="text/javascript">
const statusList = [
    {
        label: '正常',
        value: '1',
    },
    {
        label: '停运',
        value: '2',
    },
    {
        label: '维修',
        value: '3',
    },
    {
        label: '报废',
        value: '4',
    },
]
const typeList = [
    {
        label: '公交车',
        value: 0,
    },
    {
        label: '货车',
        value: 1,
    },
]
const formRules = {
    plateNum: [{ required: true, message: '请输入内容', trigger: 'blur' }],
    customNo: [{ required: true, message: '请输入内容', trigger: 'blur' }],
    vin: [{ required: true, message: '请输入内容', trigger: 'blur' }],
    orgId: [{ required: true, message: '请输入内容', trigger: 'change' }],
}

import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import { getFuelType } from '@/common/js/wordbook.js'
export default {
    data() {
        let FuelTypeList = getFuelType();
        return {
            type: 'add',
            dialogVisible: false,
            disabled: false,
            formData: {
                plateNum: '',
                customNo: '',
                vin: '',
                orgId: '',
                vendor: '',
                model: '',
                status: '',
                vType: '',
                batterV: '',
                color: '',
                fuelT: '',
                purchaseD: '',
                seats: '',
                maxiNum: '',
            },
            statusList,
            typeList,
            formRules,
            orgNa: '',
            fuelTs:[
                ...FuelTypeList
            ]
        }
    },
    computed: {
        title: function() {
            let t = '添加车辆'
            if (this.type == 'update') {
                t = '编辑车辆信息'
            }
            return t
        },
    },
    components: {
        institutionsTree,
    },
    watch: {
        dialogVisible: function(val) {
            if (!val) {
                this.$refs['addUpdateFrom'].resetFields()
                this.formData = {
                    plateNum: '',
                    customNo: '',
                    vin: '',
                    orgId: '',
                    vendor: '',
                    model: '',
                    status: '',
                    vType: '',
                    batterV: '',
                    color: '',
                    fuelT: '',
                    purchaseD: '',
                    seats: '',
                    maxiNum: ''
                }
                this.orgNa = ''
            }
        },
    },
    methods: {
        init(option, type) {
            this.type = type || 'add'
            if (option) {
                this.formData = {
                    ...this.formData,
                    ...option,
                }
                this.orgNa = option.orgNa
            }
            this.dialogVisible = true
        },
        save() {
            this.$refs['addUpdateFrom'].validate(valid => {
                if (valid) {
                    console.log(this.formData)
                    let url = '/base/vehicle/add'
                    if (this.type == 'update') {
                        url = '/base/vehicle/update'
                    }
                    let params = {
                        ...this.formData,
                    }
                    this.$http.post(url, params).then(res => {
                        this.$emit('finished')
                        this.$message({
                            type: 'success',
                            message: res.msg,
                        })
                        this.dialogVisible = false
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        cancel() {
            this.dialogVisible = false
        },
        // 选择
        selectInstitutionsLine() {
            this.$refs.institutions.init();
        },
        addInstitutions(data) {
            console.log(data);
            this.formData.orgId = data.orgId;
            this.orgNa = data.orgNa;
        },
    },
}
</script>
